<template>
  <div v-if="item" class="article">
    <div class="container">
      <div class="full-recipe__nav">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}
        </locale-router-link>
        <locale-router-link
          to="cooking"
          class="crumbs-link cooking-with-magnum"
        >
          {{ $t("cookingWithMagnum") }}
        </locale-router-link>
        <locale-router-link to="recipes" class="crumbs-link recipes-link">
          {{ $t("recipeshome") }}
        </locale-router-link>
        <a class="crumbs-link full-recipe-link"> Сыпники из творога</a>
      </div>

      <div class="full-recipe__img">
        <img :src="getImage(item)" alt="artice image" />
        <div class="full-recipe__header">
          <h1 class="full-recipe__title">Сырники из творога</h1>
          <div class="full-recipe__info-wrapper">
            <div class="full-recipe__views">2105</div>
            <div class="full-recipe__time">{{ getDate(item) }}</div>
          </div>
        </div>
      </div>

      <div class="article__main">
        {{ item.attributes.text }}
      </div>

      <articles-similar />
    </div>
  </div>
</template>

<script>
import { getNewsById } from "@/api/news";
export default {
  name: "FullPost",
  components: {
    ArticlesSimilar: () => import("@/components/articles/ArticlesSimilar.vue"),
  },
  data() {
    return {
      item: null,
    };
  },
  watch: {
    "$route.params.id": {
      deep: true,
      handler(id) {
        getNewsById(id).then((article) => {
          this.item = article;
        });
      },
    },
  },
  created() {
    getNewsById(this.$route.params.id).then((article) => {
      this.item = article;
    });
  },
  methods: {
    getImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.image?.data?.attributes?.url
      );
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes.publishedAt);
      return new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr);
    },
  },
};
</script>

<style scoped></style>
